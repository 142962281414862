import * as React from "react"
import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import { Avatar, List, Typography } from "antd"
import PageContent from "../../components/page-content"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { htmlSerializer } from "../../utils/html-serializer"
import { JsonDebugger } from "../../components/json-debugger"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const { Title } = Typography

const PressIndex: React.FC<{
  data: {
    allPrismicNewsArticle: {
      edges: unknown[]
    }
  }
}> = ({
  data: {
    allPrismicNewsArticle: { edges: articles = [] },
  },
}) => {
  if (!articles) return null

  return (
    <Layout>
      <Seo title={"Press"} />
      <PageContent>
        <Title>Press</Title>
        <List
          itemLayout="horizontal"
          dataSource={articles}
          renderItem={({ node: { data: item } }) => (
            <List.Item key={item.uid}>
              <List.Item.Meta
                avatar={
                  <GatsbyImage
                    image={getImage(
                      item.media_image.thumbnails.Thumbnail.gatsbyImageData
                    )}
                    alt={
                      item.media_image.thumbnails?.Thumbnail?.alt || item.headline
                    }
                  />
                }
                description={
                  <>
                    <Title level={4}>
                      <a href={item.url.url} target={item.url.target}>
                        {item.headline}
                      </a>
                    </Title>
                    <RichText
                      render={item.description.richText}
                      htmlSerializer={htmlSerializer}
                    />
                    {item.publication} - {item.publication_date}
                  </>
                }
              />
            </List.Item>
          )}
        />
        {/* <JsonDebugger json={{ articles }} /> */}
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query PressIndex {
    allPrismicNewsArticle(
      sort: { fields: data___publication_date, order: DESC }
    ) {
      edges {
        node {
          data {
            headline
            description {
              richText
            }
            publication
            publication_date(formatString: "MMMM Do YYYY", locale: "en-us")
            url {
              url
              target
            }
            media_image {
              thumbnails {
                Thumbnail {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PressIndex
