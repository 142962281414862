// const { MdDocumentScanner } = require("react-icons/md")

// Build a url using document data, if it doesn't exist make a query for it
function resolvePageUrl(doc) {
  let url = `/${doc.uid}/`
  if (doc.uid === "videos") {
    url = "/learn/videos/"
  }
  if (doc.data && doc.data.url_prefix) {
    url = `${doc.data.url_prefix}/${doc.uid}/`
  } else if (doc.data && doc.data.urlPrefix) {
    url = `${doc.data.urlPrefix}/${doc.uid}/`
  } else if (doc.url_prefix) {
    url = `${doc.url_prefix}/${doc.uid}/`
  } else if (doc.urlPrefix) {
    url = `${doc.urlPrefix}/${doc.uid}/`
  } else {
    // Default
    // url = `/${doc.uid}/`
  }
  // console.log("resolvePageUrl", url, doc)
  return url
}

//import { graphql, Link } from "gatsby"
const suggestProductTypeSlug = (productDoc) => {
  // console.dir({ productDoc }, { depth: null })
  const defaultSlug = "unknown"
  if (productDoc?.data?.product_type?.uid) {
    return productDoc.data.product_type.uid
  }
  if (!productDoc.tags || productDoc.tags.length <= 0) {
    return defaultSlug
  }
  if (productDoc.tags.includes("Cannabis Nutrient")) {
    return "cannabis-nutrients"
  }
  if (productDoc.tags.includes("Bio Minerals")) {
    return "microbials"
  }
  if (productDoc.tags.includes("Microbial")) {
    return "microbials"
  }
  console.warn("Unknown product type, returning default slug", { defaultSlug })
  return defaultSlug
}
// const lookupProductType = (uid) => {}
const linkResolver = (doc) => {
  const productBase = "products"
  // console.log(doc)
  // const urlPrefix = doc?.data?.url_prefix || ""
  // let urlPrefix = ""

  switch (doc.type) {
    case "page":
      // const url = `${urlPrefix}/${doc.uid}/`
      // console.log("Page", url, doc)
      // return url
      // return `/${doc.uid}/`
      return resolvePageUrl(doc)
    case "chemical_element":
      return `/learn/elements/${doc.uid}/`
    case "testimonial":
      return `/testimonials/${doc.uid}/`
    case "product_type":
      return `/${productBase}/${doc.uid}/`
    case "product":
      // https://community.prismic.io/t/linkresolver-and-nested-paths/96/15

      // const { uid: typeSlug = suggestProductTypeSlug(doc) } =
      //   doc?.data?.product_type || {}
      // Turns out extra data might not always exist :(
      const typeSlug = suggestProductTypeSlug(doc)
      return `/${productBase}/${typeSlug}/${doc.uid}/`
    case "faq_category":
      return `/learn/faq/${doc.uid}/`
    case "faq":
      const categoryUrl = linkResolver(doc.data.category) || "/"
      return `${categoryUrl}${doc.uid}/`
    default:
      console.warn("Unknown doc type, returning / for url", { type: doc.type })
      return "/"
  }
  // if (doc.type === "page") {
  //   return `/${doc.uid}/`
  // }

  // if (doc.type === "product_type") {
  //   return `/${productBase}/${doc.uid}/`
  // }
  // if (doc.type === "product") {
  //   const { uid: typeSlug } = doc?.data?.product_type
  //   // return `/${productBase}/${typeSlug}/${doc.data.shopify_product.handle}/`
  //   return `/${productBase}/${typeSlug}/${doc.uid}/`
  // }

  // // if (doc.type === 'product') {
  // //   return `/product/${doc.uid}`
  // // }

  // return "/"
}

const resolveFaqUrl = (faqDoc) => {
  console.dir({ faqDoc }, { depth: null })
}

exports.linkResolver = linkResolver
